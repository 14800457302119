import {
  Loading,
  Notification
} from "element-ui";
import Promise from "es6-promise";
import axios from 'axios';
let loadingInstance = null;

export function startInterceptor(axios) {
  const noticeOptions = {
    title: '错误',
    type: 'error'
  };
  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    //loadingInstance = Loading.service({fullscreen: true});
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
  // Add a response interceptor
  axios.interceptors.response.use((response) => {
      // Do something with response data\
      // loadingInstance.close();
      switch (response.status) {
        case 202:
        case 200:
          if (response.config.responseType === 'blob') {
            try {
              const data = response.data ? response.data : {}
              if (Object.prototype.toString.call(data) === '[object Blob]') {
                return response
              } else {
                if (data.errorCode === 0) {
                  return response
                } else if (data.errorCode === 401) {
                  window.router.replace({
                    path: '/login'
                  })
                } else {
                  Notification({
                    ...noticeOptions,
                    message: data.message
                  })
                }
              }
            } catch (e) {
              return response
            }
          } else {
            let data = response.data ? response.data : {};
            if (+data.errorCode === 0) {
              return response;
            } else {
              if (+data.errorCode === 1 || +data.errorCode === 405 || +data.errorCode === 402) {
                Notification({
                  ...noticeOptions,
                  message: '失败:' + data.errorMessage
                });
              } else if (+data.errorCode === 406) {
                Notification({
                  ...noticeOptions,
                  message: '登录失败:' + data.errorMessage
                });
              } else if (+data.errorCode === 101) {
                Notification({
                  ...noticeOptions,
                  message: '系统错误:' + data.errorMessage
                });
              } else {
                //发现返回没有合理结构的时候，报未知错误
                if (typeof data.errorCode == 'undefined') {
                  data = {
                    errorCode: -1,
                    errorMessage: '服务器无正确返回值'
                  };
                }
                Notification({
                  ...noticeOptions,
                  message: '其他错误:' + data.errorMessage
                });
              }
              return Promise.reject(data);
            }
          }


          break;
        default:
          Notification({
            ...noticeOptions,
            message: '失败:服务器响应失败...'
          });
          break;
      }
      return response;
    },
    (error) => {
      // loadingInstance.close();
      for (let o in error) {
        //   console.log(error[o])
      }
      //console.log('interceptor......')
      if (axios.isCancel(error)) {
        return Promise.reject(new Error('request cancelled'))
      }
      const response = error.response;
      // Do something with response error
      switch (response.status) {
        case 0:
        case 400:
        case 404:
          Notification({
            ...noticeOptions,
            message: '失败:服务器异常,code:' + response.status
          });
          window.router.push({
            path: '/error',
            query: {
              code: response.status
            }
          });
          break;
        case 401:
          /* Notification({
           ...noticeOptions,
           message: '失败:没有足够权限'
           });*/
          window.router.push({
            path: '/login'
          });
          break;
        case 405:
          Notification({
            ...noticeOptions,
            message: '失败:没有足够权限'
          });
          // window.router.push({
          //   path: '/login'
          // });
          break;
        default:
          Notification({
            ...noticeOptions,
            message: '失败:服务器响应失败...'
          });

          break;
      }
      return Promise.reject({
        errorCode: response.status
      });
    }
  );
}