/**
 *  因为是移植我们自己的react-native项目中的脚手架的api.js，
 *  在RN中使用的Fetch 在此文中我们使用axios 所以部分code会显得有点啰嗦
 */

import Promise from "es6-promise";
import _ from "lodash";
import axios from "axios";
import {API_ROOT, REQUEST_TIMEOUT} from "../../config";
//import {getAuthenticationToken} from "../utils/authentication";
import Serialize from "./serialize";
import {startInterceptor} from "./interceptor";
startInterceptor(axios);
//import store from "../redux/store";
//import EventEmitter from "eventemitter2";

/**
 * All HTTP errors are emitted on this channel for interested listeners
 */
//export const errors = new EventEmitter();

/**
 * GET a path relative to API root url.
 * @param {String}  path Relative path to the configured API endpoint
 * @param {Object}  params The configured API params
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 * @returns {Promise} of response body
 */

/**
 *
 * @param path {String}  path Relative path to the configured API endpoint
 * @param params {Object}  params The configured API params
 * @param config {Object}  params The configured axios config  eg. timeout
 * @param suppressRedBox
 * @returns {Promise}
 */
export async function get(path, params, config = {}, suppressRedBox) {
  let serParams = '';
  let finalPath = path;
  if (_.isObject(params)) {
    serParams = Serialize(params);
    if (path.indexOf('?') > -1) {
      finalPath = path + '&' + serParams;
    } else {
      finalPath = path + '?' + serParams;
    }
  }
  return bodyOf(request('get', finalPath, null, config, suppressRedBox));
}

/**
 * POST JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 * @param config {Object}  params The configured axios config  eg. timeout
 * @returns {Promise}  of response body
 */
export async function post(path, body, config = {}, suppressRedBox) {
  return bodyOf(request('post', path, body, config, suppressRedBox));
}

/**
 * PUT JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param config {Object}  params The configured axios config  eg. timeout
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 * @returns {Promise}  of response body
 */
export async function put(path, body, config = {}, suppressRedBox) {
  return bodyOf(request('put', path, body, config, suppressRedBox));
}

/**
 * DELETE a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 * @param config {Object}  params The configured axios config  eg. timeout
 * @returns {Promise}  of response body
 */
export async function del(path, body, config = {}, suppressRedBox) {
  return bodyOf(request('delete', path, body, config, suppressRedBox));
}

/**
 * POST JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} file Anything that you can pass to JSON.stringify
 * @param config {Object}  params The configured axios config  eg. timeout
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 * @returns {Promise}  of response body
 */
export async function upload(path, file, config = {}, suppressRedBox) {
  return bodyOf(request('post', path, file, config, suppressRedBox));
}


/**
 * Make arbitrary fetch request to a path relative to API root url
 * @param {String} method One of: get|post|put|delete
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param config {Object}  params The configured axios config  eg. timeout
 * @param {Boolean} suppressRedBox If true, no warning is shown on failed request
 */
export async function request(method, path, body, config, suppressRedBox) {
  try {
    const response = await sendRequest(method, path, body, config, suppressRedBox);
    return handleResponse(
      path,
      response,
      config
    );
  }
  catch (error) {
    const errorStr = error.toString();
    if (errorStr.indexOf('Network') > -1) {
      setTimeout(() => {  //网络
        request(method, path, body, config, suppressRedBox);
      }, REQUEST_TIMEOUT)
    }
    if (errorStr.indexOf('cancelled') > -1) {
      return Promise.reject('request cancelled');
    }
    //typeof (error.errorCode)!=='undefined' 服务器返回的业务上逻辑的错误
    if (!suppressRedBox && typeof (error.errorCode) === 'undefined') {
      logError(error, url(path), method);
    }
    throw error;
  }
}

/**
 * 相对路径补上全路径，实际axios已经提供该方法
 */
export function url(path) {
  if (path.indexOf('http://') > -1 || path.indexOf('https://') > -1) {
    return path;
  } else {
    return path.startsWith('/')
      ? API_ROOT + path
      : API_ROOT + '/' + path;
  }
}

/**
 * Constructs and fires a HTTP request
 */
async function sendRequest(method, path, body, config) {
  try {

    const fullpath = url(path);
    //const token = await getAuthenticationToken();
    let options = null;

    if (body && body.formType === 'file' && body.formData) {  //鸭子类型检测 让他认为是一个上传
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      };
      //options = {method, headers, body};
      config.timeout = 0;//上传行为不需要超时
      options = {method, headers, data: body.formData};
    } else {
      const headers = getRequestHeaders(body, null);
      options = _.isObject(body)
        // ? {method, headers, body: Serialize(body)}
        ? {method, headers, data: Serialize(body)}
        : {method, headers};
    }
    const timeout = Number.isInteger(config.timeout) ? config.timeout : REQUEST_TIMEOUT;
    delete config.timeout;
    return axios({
      url: fullpath,
      withCredentials: true,
      ...config,
      timeout,
      ...options
    });
    // return timeout(fetch(endpoint, options), TIMEOUT);
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Receives and reads a HTTP response
 */
async function handleResponse(path, response, config = {}) {
  try {
    const status = response.status;

    // `fetch` promises resolve even if HTTP status indicates failure. Reroute
    // promise flow control to interpret error responses as failures
    if (status >= 400) {
      const message = await getErrorMessageSafely(response);
      const error = new HttpError(status, message);

      // emit events on error channel, one for status-specific errors and other for all errors
      // errors.emit(status.toString(), {path, message: error.message});
      // errors.emit('*', {path, message: error.message}, status);
      throw error;
    }
    if (config.responseType === 'blob') {
      let responseBody = await response.data
      try {
        if (Object.prototype.toString.call(responseBody) === '[object Blob]') {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }) // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          // console.warn(response.headers['content-disposition'])
          const rowName = response.headers['content-disposition'] || ''
          let fileName = ''
          if (rowName) {
            fileName = decodeURIComponent(rowName).split('filename=')[1]
          }
          if (config.SuffixName === 'zip') {
            downloadElement.download = DOWNLOAD_PREFIX + '_' + new Date().getTime() + '.zip' // 下载后文件名
          } else {
            downloadElement.download = fileName
            // downloadElement.download = DOWNLOAD_PREFIX + '_' + new Date().getTime() + '.xlsx' // 下载后文件名
          }
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象 ;
          responseBody = {}
        }
      } catch (e) {
        responseBody = {}
      }

      return {
        status: response.status,
        headers: response.headers,
        body: responseBody
      }
    } else {
      const responseBody = await response.data
      return {
        status: response.status,
        headers: response.headers,
        body: responseBody || null
      }
    }
   
  } catch (e) {
    throw e;
  }
}

function getRequestHeaders(body, token) {
  const headers = body
    ? {'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}
    : {'Accept': 'application/json'};

  if (token) {
    return {...headers, Authorization: token};
  }

  return headers;
}

// try to get the best possible error message out of a response
// without throwing errors while parsing
async function getErrorMessageSafely(response) {
  try {
    const body = await response.data;
    if (!body) {
      return '';
    }

    // Optimal case is JSON with a defined message property
    const payload = JSON.parse(body);
    if (payload && payload.message) {
      return payload.message;
    }

    // Should that fail, return the whole response body as text
    return body;

  } catch (e) {
    // Unreadable body, return whatever the server returned
    return response._bodyInit;
  }
}

/**
 * Rejects a promise after `ms` number of milliseconds, it is still pending
 */
function timeout(promise, ms) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => reject(new Error('timeout')), ms);
    promise
      .then(response => {
        clearTimeout(timer);
        resolve(response);
      })
      .catch(reject);
  });
}

async function bodyOf(requestPromise) {
  try {
    const response = await requestPromise;
    return response.body;
  } catch (e) {
    throw e;
  }
}

/**
 * Make best effort to turn a HTTP error or a runtime exception to meaningful error log message
 */
function logError(error, endpoint, method) {
  if (error.status) {
    const summary = `(${error.status} ${error.statusText}): ${error._bodyInit}`;
    console.error(`API request ${method.toUpperCase()} ${endpoint} responded with ${summary}`);
  }
  else {
    console.error(`API request ${method.toUpperCase()} ${endpoint} failed with message "${error.message}"`);
  }
  /*NavigationState.pushRoute({
   key: 'Error',
   title: '错误页面'
   });*/
}
